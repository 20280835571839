import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Box, Button, Spinner as Spinning } from 'grommet';
import { User as UserIcon } from 'grommet-icons';
import { Helmet } from 'react-helmet';

import { isPresent } from 'utils/HelperMethods';
import { parseLocationToParams, buildUrl } from 'utils/UrlHelper';
import { isBoardexValid } from 'utils/HelperMethods';

import { withAuthenticator } from 'containers/Authenticator';

import SingleSignOnRepository from 'repositories/SingleSignOnRepository';

import LoginForm from './components/LoginForm';
import TwoFactorAuthenticationForm from './components/TwoFactorAuthenticationForm';

import CompanyPresenter from 'presenters/CompanyPresenter';
import SingleSignOnPresenter from 'presenters/SingleSignOnPresenter';

import styles from './SignInPage.module.css';

class SignInPageComponent extends Component {
  static propTypes = {
    onUserLogin: PropTypes.func,
    onConfirm2FA: PropTypes.func,
    onUpdatPhoneFor2FA: PropTypes.func,
    onResendConfirmation: PropTypes.func,
    history: PropTypes.shape().isRequired,
    match: PropTypes.shape().isRequired,
    onLoadCompany: PropTypes.func,
    company: CompanyPresenter.shape(),
    isCompanyLoading: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    const { history } = this.props;
    const { twoFactorAuthentication, redirect_to: redirectTo } = parseLocationToParams(history.location);
    const redirectToUrl = isPresent(redirectTo) ? decodeURIComponent(redirectTo) : null;

    this.state = { twoFactorAuthentication, redirectToUrl };
  }

  componentDidMount() {
    const {
      match: {
        params: { companySlug },
      },
      onLoadCompany,
    } = this.props;

    if (isPresent(companySlug)) {
      onLoadCompany(companySlug);
    }
  }

  handleUserLogin = (params) => {
    const { onUserLogin, history } = this.props;
    let { redirectToUrl } = this.state;

    const {
      source,
      sign_in_url: signInUrl,
      profile,
      success_path: successPath,
      redirectToPath,
    } = parseLocationToParams(history.location);
    const url = buildUrl(successPath, { source, sign_in_url: signInUrl, profile });
    if (isBoardexValid({ source, url })) {
      redirectToUrl = url;
    }
    if (redirectToPath) {
      redirectToUrl = buildUrl(redirectToPath);
    }

    return onUserLogin(params, { redirectToUrl }).then((data) => {
      const { twoFactorAuthentication } = data;

      this.setState({ ...this.state, twoFactorAuthentication });
    });
  };

  handleSingleSignOn = () => {
    const { company, history } = this.props;
    const singleSignOn = CompanyPresenter.singleSignOn(company);
    const ssoId = SingleSignOnPresenter.id(singleSignOn);

    const {
      source,
      sign_in_url: signInUrl,
      profile,
      success_path: successPath,
    } = parseLocationToParams(history.location);
    const params = { source, sign_in_url: signInUrl, success_path: successPath, profile };

    SingleSignOnRepository.passthrough(ssoId, params).then(({ redirectUrl }) => {
      window.location.href = redirectUrl;
    });
  };

  handleConfirm2FA = (params) => {
    const { onConfirm2FA } = this.props;
    const { redirectToUrl } = this.state;

    return onConfirm2FA(params, { redirectToUrl });
  };

  render() {
    const {
      onUpdatPhoneFor2FA,
      onResendConfirmation,
      company,
      isCompanyLoading,
      match: {
        params: { companySlug },
      },
      history,
    } = this.props;
    const singleSignOn = CompanyPresenter.singleSignOn(company);
    const isSsoAvailable = SingleSignOnPresenter.isAvailable(singleSignOn);
    const { twoFactorAuthentication } = this.state;
    const { source } = parseLocationToParams(history.location);
    const isBoardex = source && source.toLowerCase() === 'boardex';

    return (
      <Box direction="column" align="center" full="vertical" justify="center" margin="none" className={styles.login}>
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        {!isBoardex &&
          (isPresent(twoFactorAuthentication) ? (
            <TwoFactorAuthenticationForm
              twoFactorAuthentication={twoFactorAuthentication}
              onUpdatPhone={onUpdatPhoneFor2FA}
              onConfirm={this.handleConfirm2FA}
              onResendConfirmation={onResendConfirmation}
            />
          ) : (
            <LoginForm onSubmit={this.handleUserLogin} />
          ))}
        {!isCompanyLoading && companySlug && (
          <Button
            icon={<UserIcon />}
            className={styles.ssoButton}
            label="Single Sign On"
            onClick={isSsoAvailable && this.handleSingleSignOn}
          />
        )}
        {isCompanyLoading && <Button icon={<Spinning />} className={styles.ssoButton} label="Search Company..." />}
      </Box>
    );
  }
}

const SignInPage = withAuthenticator(
  SignInPageComponent,
  ({ authenticate, confirm2FA, updatePhoneNumberFor2FA, resendConfirmationFor2FA, loadCompany }) => ({
    onUserLogin: authenticate,
    onConfirm2FA: confirm2FA,
    onUpdatPhoneFor2FA: updatePhoneNumberFor2FA,
    onResendConfirmation: resendConfirmationFor2FA,
    onLoadCompany: loadCompany,
  }),
);

export default withRouter(SignInPage);
